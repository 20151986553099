<template>
  <validation-observer
    ref="reviewBoardSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="reviewBoardSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm hội đồng nghiệm thu sáng kiến' : 'Cập nhật hội nghiệm thu sáng kiến'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên hội đồng <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tên hội đồng"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetReviewBoard.name"
              name="name"
              placeholder="Nhập tên hội đồng"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="year">
          <template v-slot:label>
            Năm học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Năm học"
            rules="required"
          >
            <v-select
              id="year"
              v-model="targetReviewBoard.yearId"
              label="name"
              :options="years"
              :reduce="(option) => option.id"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Ngày thành lập"
          label-for="name"
        >
          <flat-pickr
            v-model="targetReviewBoard.establishDate"
            class="form-control"
            :config="config"
          />
        </b-form-group>

        <b-form-group
          label="Số quyết định"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="targetReviewBoard.establishDocNo"
            name="name"
            placeholder="Nhập số quyết định"
          />
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetReviewBoard.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('reviewBoardSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import moment from 'moment'
import { Flag } from '@/const/flag'

export default {
  name: 'ReviewBoardSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    reviewBoard: {
      type: Object,
      default: undefined,
    },
    years: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetReviewBoard: {
        organizationId: null,
        name: null,
        yearId: null,
        establishDocNo: null,
        establishDate: null,
        status: Flag.ACTIVE,
        orderNo: 1,
      },
      config: {
        enableTime: false,
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: true,
      },
      required,
    }
  },
  computed: {
    statusOptions() {
      return STATUSES
    },
    isCreated() {
      return !this.reviewBoard
    },
  },
  methods: {
    ...mapActions({
      createReview: 'researchInnovationReviewCouncil/create',
      updateReview: 'researchInnovationReviewCouncil/update',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.reviewBoard) {
        this.targetReviewBoard = { ...this.reviewBoard }
        this.targetReviewBoard.establishDate = moment(this.targetReviewBoard.establishDate, 'DD/MM/YYYY').toDate()
      } else {
        this.targetReviewBoard = { ...this.targetReviewBoard, organizationId: this.user.orgId }
      }
    },
    onHide() {
      this.$refs
        .reviewBoardSaveFormRef
        .reset()
      this.targetReviewBoard = {
        organizationId: null,
        name: null,
        yearId: null,
        establishDocNo: null,
        establishDate: null,
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .reviewBoardSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createReview(this.targetReviewBoard)
            : await this.updateReview(this.targetReviewBoard)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('reviewBoardSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.flatpickr-wrapper {
  width: 100%;
}
</style>
