<template>
  <validation-observer
    ref="reviewerSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="reviewerSaveModal"
      body-class="position-static"
      centered
      :title="isUpdated ? 'Cập nhật thành viên' : 'Thêm thành viên'"
      :no-close-on-backdrop="true"
      @shown="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="employeeIds">
          <template v-slot:label>
            Cán bộ <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Cán bộ"
            rules="required"
          >
            <v-select
              id="employeeIds"
              v-model="targetReviewer.employeeIds"
              multiple
              class="reviewer-select"
              label="name"
              :options="employees"
              :reduce="option => option.id"
              :disabled="isUpdated"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="roleId">
          <template v-slot:label>
            Vai trò <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Vai trò"
            rules="required"
          >
            <v-select
              id="roleId"
              v-model="targetReviewer.roleId"
              label="name"
              :options="roles"
              :reduce="option => option.id"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetReviewer.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="orderNo">
          <template v-slot:label>
            Thứ tự <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thứ tự"
            rules="required"
          >
            <b-form-input
              id="orderNo"
              v-model="targetReviewer.orderNo"
              type="number"
              name="orderNo"
              placeholder="Nhập thứ tự"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-if="!isUpdated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('reviewerSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { STATUSES } from '@/const/status'
import { required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ResearchRoleType } from '@/const/type'
import { Flag } from '@/const/flag'

export default {
  name: 'ReviewerSave',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BModal,
    BOverlay,
    vSelect,
    BFormInvalidFeedback,
  },
  props: {
    reviewBoardId: {
      type: Number,
      default: null,
    },
    reviewer: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetReviewer: {
        id: null,
        employeeIds: [],
        roleId: null,
        status: Flag.ACTIVE,
        orderNo: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      employees: 'researchInnovationReviewer/employees',
      roles: 'factResearchRoles/dataArr',
    }),
    statusOptions() {
      return STATUSES
    },
    isUpdated() {
      return !!this.reviewer
    },
  },
  methods: {
    ...mapActions({
      getEmployeesByOrganizationId: 'researchInnovationReviewer/getEmployeesByOrganizationId',
      getRoles: 'factResearchRoles/getRolesByType',
      createReviewers: 'researchInnovationReviewer/createReviewers',
      updateReviewer: 'researchInnovationReviewer/updateReviewer',
    }),
    async onShow() {
      if (this.reviewer) {
        this.targetReviewer = { ...this.reviewer }
      }
      this.isLoading = true
      try {
        await Promise.all([
          this.getEmployeesByOrganizationId(),
          this.getRoles({ type: ResearchRoleType.REVIEWER }),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .reviewerSaveFormRef
        .reset()
      this.targetReviewer = {
        id: null,
        employeeIds: [],
        roleId: null,
        status: Flag.ACTIVE,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onSave(type = null) {
      const valid = this.$refs
        .reviewerSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isUpdated
            ? await this.updateReviewer({
              id: this.targetReviewer.id,
              roleId: this.targetReviewer.roleId,
              status: this.targetReviewer.status,
              orderNo: this.targetReviewer.orderNo,
            })
            : await this.createReviewers({
              researchInnovationReviewCouncilId: this.reviewBoardId,
              roleId: this.targetReviewer.roleId,
              employeeIds: this.targetReviewer.employeeIds,
              status: this.targetReviewer.status,
              orderNo: this.targetReviewer.orderNo,
            })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('reviewerSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.reviewer-select {
  ::v-deep .vs__selected {
    color: white;
    background-color: #7367f0;
  }
}
</style>
