var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"reviewerSaveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"reviewerSaveModal","body-class":"position-static","centered":"","title":_vm.isUpdated ? 'Cập nhật thành viên' : 'Thêm thành viên',"no-close-on-backdrop":true},on:{"shown":_vm.onShow,"hide":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end"},[(!_vm.isUpdated)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.onSave}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu và tiếp tục ")],1)]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave('hide')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('reviewerSaveModal')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" Hủy ")],1)])],1)]},proxy:true}],null,true)},[_c('b-form',[_c('b-form-group',{attrs:{"label-for":"employeeIds"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Cán bộ "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Cán bộ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"reviewer-select",attrs:{"id":"employeeIds","multiple":"","label":"name","options":_vm.employees,"reduce":function (option) { return option.id; },"disabled":_vm.isUpdated},model:{value:(_vm.targetReviewer.employeeIds),callback:function ($$v) {_vm.$set(_vm.targetReviewer, "employeeIds", $$v)},expression:"targetReviewer.employeeIds"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"roleId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Vai trò "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Vai trò","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"roleId","label":"name","options":_vm.roles,"reduce":function (option) { return option.id; }},model:{value:(_vm.targetReviewer.roleId),callback:function ($$v) {_vm.$set(_vm.targetReviewer, "roleId", $$v)},expression:"targetReviewer.roleId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trạng thái "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trạng thái","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.targetReviewer.status),callback:function ($$v) {_vm.$set(_vm.targetReviewer, "status", $$v)},expression:"targetReviewer.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"orderNo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Thứ tự "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Thứ tự","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"orderNo","type":"number","name":"orderNo","placeholder":"Nhập thứ tự","state":_vm.getElementState(errors)},model:{value:(_vm.targetReviewer.orderNo),callback:function ($$v) {_vm.$set(_vm.targetReviewer, "orderNo", $$v)},expression:"targetReviewer.orderNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-overlay',{attrs:{"variant":"white","spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm","no-wrap":"","show":_vm.isLoading}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }